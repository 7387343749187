import servicesImg from '../mahadev2.jpg';
import { useState } from 'react';
import spiritualHealingImg from '../spiritual_realm.png';
import psychicReadingsImg from '../palmistry.jpg';
import blackMagicRemovalImg from '../blackmagic.png';

export default function Services() {
    const [index, setIndex] = useState(0);
    const isPortrait = window.innerHeight > window.innerWidth;

    const handleNext = () => {
        if (index < services.length - 1) {
            setIndex(index + 1);
        } else {
            setIndex(0);
        }
    }

    const handlePrev = () => {
        if (index > 0) {
            setIndex(index - 1);
        } else {
            setIndex(services.length - 1);
        }
    }

    const services = [
        {
            title: 'Spiritual Healing',
            description: "A treatment that involves the transfer of energy through the healer to the recipient. It promotes self-healing by relaxing the body, releasing tensions and strengthening the body’s own immune system. Healing is natural and non-invasive with the intention of bringing the recipient into a state of balance and wellbeing on all levels",
            image: spiritualHealingImg,
        },
        {
            title: 'Psychic Readings',
            description: "Astrology, numerology, and palm readings are forms of psychic readings, which are used to gain insight into a person’s life and future. Psychic readings can help you understand your past, present, and future, and provide guidance on how to overcome obstacles and achieve your goals. ",
            image: psychicReadingsImg,
        },
        {
            title: 'Black Magic Removal',
            description: 'Black magic, voodoo, and witchcraft are forms of dark magic that can cause harm to individuals and their loved ones. Black magic removal is a process that involves removing the negative energy and spells that have been cast on a person. The healer will use spiritual techniques to break the spells and protect the person from further harm.',
            image: blackMagicRemovalImg,
        },
    ]
    return (
        <>
            <div className='services-title creed-fontface-large'>Services</div>
            {isPortrait ?
            <div className='services-btns-container'>
                <div className='services-btns-subcontainer'>
                    <button className='services-btn creed-fontface-medium' onClick={handlePrev}>{"<"}</button>
                    <button className='services-btn creed-fontface-medium' onClick={handleNext}>{">"}</button>
                </div>
            </div> : null}
            <div className='main-container-split'>
                {isPortrait ? null :
                <div className="sub-container-split-img">
                    <img className="service-img" src={services[index].image} />
                </div>}
                <div className="sub-container-split-text">
                    <h3 className='creed-fontface-medium'>{services[index].title}</h3>
                    <h4 className='creed-fontface-small sub-container-text-services'>
                        {services[index].description}
                    </h4>
                </div>
                
                {isPortrait ?
                <div className="sub-container-split-img">
                    <img className="service-img" src={services[index].image} />
                </div> : null}
            </div>
            {isPortrait ? null :
            <div className='services-btns-container'>
                <div className='services-btns-subcontainer'>
                    <button className='services-btn creed-fontface-medium' onClick={handlePrev}>{"<"}</button>
                    <button className='services-btn creed-fontface-medium' onClick={handleNext}>{">"}</button>
                </div>
            </div>}
        </>
    );
}