import aboutImg from '../mahadev1.png';

export default function About() {
    return (
        <>
            <div className='main-container-split'>
                <div className="sub-container-split-text">
                    <h1 className='creed-fontface-large'>About</h1>
                    <h4 className='creed-fontface-small sub-container-text'>
                        Destroyer Mahadev is a master of spiritual healing. He has been practicing for over 20 years and comes from a family with a long line of healers, astrologers, and psychic readers. Mahadev is known for his accuracy and his ability to help people overcome their problems and live a better life. He has helped thousands of people around the world and has a reputation for being one of the best spiritual healers in the world. 
                        <br /><br />
                        Mahadev is dedicated to helping people find peace, happiness, and success in their lives. Contact Mahadev today to schedule a consultation and start your journey to a better life.
                    </h4>
                </div>
                <div className="sub-container-split-img">
                    <img className="sub-img" src={aboutImg} />
                </div>
            </div>
        </>
    );
}
