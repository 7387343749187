import { useState } from "react";

export default function Navbar(props) {
    const [selectedItem, setSelectedItem] = useState('');

    function handleNavbarItemClick(item) {
      try {
        props.scrollNavigate(item);
        setSelectedItem(item);
      } catch (err) {
        console.log(err);
      }
    }

    let buttonStyle = {backgroundColor:'transparent', border:'none', cursor:'pointer', color:'#E1D8CE', fontSize:'1.25vh', paddingLeft:'2vh', paddingRight:'2vh', paddingBottom:'1vh', pointerEvents:'auto'};
    let selectedButtonStyle = {backgroundColor:'transparent', border:'none', cursor:'pointer', color:'#E1D8CE', fontSize:'1.25vh', paddingLeft:'2vh', paddingRight:'2vh', paddingBottom:'1vh', pointerEvents:'auto', borderBottom:'0.25vh solid #E1D8CE'};

    return (
      <>
        <div style={{backgroundColor:'#000000', width:'100vw', height:'8.5vh', paddingTop:'2vh', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', position:'fixed', zIndex:'2', pointerEvents:'none'}}>
          <button className='creed-fontface' style={selectedItem == 'about' ? selectedButtonStyle : buttonStyle} onClick={() => handleNavbarItemClick('about')}>
            ABOUT
          </button>
          <button className='creed-fontface' style={selectedItem == 'services' ? selectedButtonStyle : buttonStyle} onClick={() => handleNavbarItemClick('services')}>
            SERVICES
          </button>
          <button className='creed-fontface' style={selectedItem == 'testimonials' ? selectedButtonStyle : buttonStyle} onClick={() => handleNavbarItemClick('testimonials')}>
            REVIEWS
          </button>
          <button className='creed-fontface' style={selectedItem == 'contact' ? selectedButtonStyle : buttonStyle} onClick={() => handleNavbarItemClick('contact')}>
            CONTACT
          </button>
        </div>
      </>
    )
}