import servicesImg from '../mahadev2.jpg';
import { useState } from 'react';
import reviewsImg from '../reviews_shiv.png';

export default function Reviews() {
    const [index, setIndex] = useState(0);
    const isPortrait = window.innerHeight > window.innerWidth;

    const handleNext = () => {
        if (index < reviews.length - 1) {
            setIndex(index + 1);
        } else {
            setIndex(0);
        }
    }

    const handlePrev = () => {
        if (index > 0) {
            setIndex(index - 1);
        } else {
            setIndex(reviews.length - 1);
        }
    }

    const reviews = [
        {
            title: 'Advaith S.',
            description: '"Changed my life. Was in a dark place and Mahadev guided me spiritually and destroyed the negative energies occupying my existence, and opened my eyes. I am forever grateful."',
            image: reviewsImg,
        },
        {
            title: 'MD Sadiyal Mursalyn',
            description: '"Great reading, I had so much fun! It felt like talking to a friend who know so much about you and much more. Everything he said was to the point, the people he described, how I feel about it. Totally recommend!"',
            image: reviewsImg,
        },
        {
            title: 'Shafat Ahsan',
            description: '"I am blessed to have found Astrologer Mahadev Ji. Whenever he read me, his psychic readings and prophecies came true. I was having some personal issues but with his help, things are much better now. His handling of both personal and professional matters is truly remarkable. Without a doubt, I will suggest it to everyone."',
            image: reviewsImg,
        },
        {
            title: 'Lathara Rivera',
            description: '"I am 100% satisfied with my reading & spiritual guidance from Mahadev. After years of searching for answers from various psychics, he was the only psychic to get straight to the source & provide accurate details. He’s genuine, kind & passionate about his work. He allowed me to ask questions and his readings is also the best price I ever paid. Thank you Mahadev so much for your time & effort. I finally have answers & peace to get on the right path."',
            image: reviewsImg,
        },
    ]
    return (
        <>
            <div className='services-title creed-fontface-large'>Reviews</div>
            {isPortrait ?
            <div className='services-btns-container'>
                <div className='services-btns-subcontainer'>
                    <button className='services-btn creed-fontface-medium' onClick={handlePrev}>{"<"}</button>
                    <button className='services-btn creed-fontface-medium' onClick={handleNext}>{">"}</button>
                </div>
            </div> : null}
            <div className='main-container-split'>
                {isPortrait ? null :
                <div className="sub-container-split-img">
                    <img className="service-img" src={reviews[index].image} />
                </div>}
                <div className="sub-container-split-text">
                    <h3 className='creed-fontface-medium'>{reviews[index].title}</h3>
                    <h4 className='creed-fontface-small sub-container-text-services'>
                        {reviews[index].description}
                    </h4>
                    <h4 className='creed-fontface-small sub-container-text-services'>
                        <a href="https://www.google.com/maps/place/MAHADEV+SOLUTIONS/@33.0235026,-96.9324237,17z/data=!4m8!3m7!1s0x864c2fb031e4c7a5:0x6ce4b1d0dbde6eb9!8m2!3d33.0235026!4d-96.9324237!9m1!1b1!16s%2Fg%2F11ldcy304c?entry=ttu">View All Reviews</a>
                    </h4>
                </div>
                
                {isPortrait ?
                <div className="sub-container-split-img">
                    <img className="service-img" src={reviews[index].image} />
                </div> : null}
            </div>
            {isPortrait ? null :
            <div className='services-btns-container'>
                <div className='services-btns-subcontainer'>
                    <button className='services-btn creed-fontface-medium' onClick={handlePrev}>{"<"}</button>
                    <button className='services-btn creed-fontface-medium' onClick={handleNext}>{">"}</button>
                </div>
            </div>}
        </>
    );
}