import { useRef } from 'react';
// import {BrowserView, MobileView} from "react-device-detect";
import NavbarMobile from '../components/NavbarMobile';
import Navbar from '../components/Navbar';
import About from './About';
import Services from './Services';
import Testimonials from './Testimonials';
import Contact from './Contact';
import landingImg from '../mahadev4.jpg';
import useWindowSize from '../hooks/useWindowSize';

export default function Home() {
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const servicesRef = useRef(null);
    const testimonialsRef = useRef(null);
    const contactRef = useRef(null);
    const [width, height] = useWindowSize();

    function scrollNavigate(ref) {
        try{
            if (ref == 'home') {
            homeRef.current.scrollIntoView({behavior: 'smooth'});
            } else if (ref == 'about') {
            aboutRef.current.scrollIntoView({behavior: 'smooth'});
            } else if (ref == 'services') {
            servicesRef.current.scrollIntoView({behavior: 'smooth'});
            } else if (ref == 'testimonials') {
                testimonialsRef.current.scrollIntoView({behavior: 'smooth'});
            } else if (ref == 'contact') {
                contactRef.current.scrollIntoView({behavior: 'smooth'});
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className='overlay'>
                <Navbar scrollNavigate={scrollNavigate} />
                <div ref={homeRef} className='main-container'>
                    <img className='landing-image' src={landingImg} />
                    <div className='landing-text-container'>
                        <h1 className='creed-fontface-large'>Destroyer Mahadev</h1>
                        <h2 className='creed-fontface-medium'>Master of Spiritual Healing</h2>
                        <div className="landing-btns-container">
                            <button className='landing-btn creed-fontface-small' onClick={() => scrollNavigate('about')}>Learn More</button>
                            <button className='landing-btn creed-fontface-small' onClick={() => scrollNavigate('contact')}>Contact</button>
                        </div>
                    </div>
                </div>
                <div ref={aboutRef}>
                    <About/>
                </div>
                <div ref={servicesRef}>
                    <Services/>
                </div>
                <div ref={testimonialsRef}>
                    <Testimonials/>
                </div>
                <div ref={contactRef}>
                    <Contact/>
                </div>
            </div>
        </>
    );
}