import contactImg from '../mahadev2.jpg';

export default function Contact() {
    const isPortrait = window.innerHeight > window.innerWidth;
    return (
        <>
            <div className='main-container-split-contact'>
                {isPortrait ? null :
                <div className="sub-container-split-img">
                    <img className="sub-img" src={contactImg} />
                </div>
                }
                <div className="sub-container-split-text">
                    <h1 className='creed-fontface-large'>Contact</h1>
                    <h4 className='creed-fontface-small sub-container-text'>
                        Phone: +1 469-739-8268
                        <br /><br />
                        Email: destroyermahadevaji@gmail.com
                        <br /><br />
                        Address: <a href="https://www.google.com/maps/place/9504+Hetta+Dr,+Frisco,+TX+75035/@33.148499,-96.8020509,17z/data=!3m1!4b1!4m6!3m5!1s0x864c3c310fd9e305:0x9cd4222f30d9f2d5!8m2!3d33.1484945!4d-96.799476!16s%2Fg%2F11pxb08f0s?entry=ttu">9504 Hetta Dr, Frisco, TX 75035</a>
                    </h4>
                </div>
                {/* {isPortrait ?
                <div className="sub-container-split-img">
                    <img className="sub-img" src={contactImg} />
                </div> : null} */}
            </div>
        </>
    );
}